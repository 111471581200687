import React from 'react';
import PropTypes from 'prop-types';
import Image from 'next/image';
import { translate } from 'react-i18next';
import moment from 'moment-timezone';
import styled from 'styled-components';

const IMAGE_BASE_PATH = '/static/images/schedule';

const StyledImage = styled(Image)`
  animation: rotation 10s infinite linear;

  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
`;

const OpeningBadge = ({ hours, i18n, size }) => {
  const isOpen = hours => {
    if (!hours) return false;

    // Current Day
    const _date = moment().tz('Europe/Paris');
    const currentDay = _date.format('dddd').toLowerCase();
    const hours_min = parseFloat(_date.hours() + '.' + _date.minute());

    // Past Day
    const _datePast = moment()
      .tz('Europe/Paris')
      .subtract(1, 'days');
    const pastDay = _datePast.format('dddd').toLowerCase();

    if (hours[currentDay]) {
      const open = parseFloat(hours[currentDay].open.replace(':', '.'));
      const close = parseFloat(hours[currentDay].close.replace(':', '.'));

      if (open && close && open <= hours_min) {
        if (close >= hours_min || close < open) {
          return true;
        }
      }
    }

    if (hours[pastDay]) {
      const open = parseFloat(hours[pastDay].open.replace(':', '.'));
      const close = parseFloat(hours[pastDay].close.replace(':', '.'));
      if (
        open &&
        close &&
        hours[pastDay].close < hours[pastDay].open &&
        hours[pastDay].close >= hours_min
      ) {
        return true;
      }
    }

    return false;
  };

  const src = `${IMAGE_BASE_PATH}/${i18n?.language || 'en'}/${
    isOpen(hours) ? 'open' : 'closed'
  }.svg`;

  return (
    <StyledImage
      src={src}
      width={size || 120}
      height={size || 120}
      objectFit="contain"
    />
  );
};

OpeningBadge.propTypes = {
  hours: PropTypes.object.isRequired,
  i18n: PropTypes.object,
  size: PropTypes.number
};

export default translate()(OpeningBadge);
